<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="3">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :clearable="false"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CSelect
                :placeholder="$t('search_a') + $t('status')"
                :options="statusOptions"
                :value.sync="searchData.status"
              />
            </CCol>
            <CCol col="12" sm="4">
              <CInput
                :placeholder="$t('search_a') + $t('order_no') + $t('or') + $t('booking_name') + $t('or') + $t('booking_phone')"
                v-model="searchData.search_str"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getOrders()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2"></CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="status" slot-scope="props">
              {{ $_.find(statusOptions, option => { return option.value === props.row.status }).label }}
            </font>
            <font slot="order_info.booking_note" slot-scope="props" v-html="props.row.order_info.booking_note?.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>')">
            </font>
            <font slot="order_info.agent_note" slot-scope="props" v-html="props.row.order_info.agent_note?.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>')">
            </font>
            <font slot="total_price" slot-scope="props">
              {{ 'NT' + props.row.total_price.toLocaleString('zh-TW', {
                                                  style: 'currency',
                                                  currency: 'TWD',
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                              }) }}
            </font>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="dark" v-bind="{ variant: 'outline' }" @click="() => orderDetailModal = { data: props.row, modal: true }">{{ $t('order_detail') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editOrderModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <EditOrderModal :data="editOrderModal.data" :show="editOrderModal.modal" @showUpdate="show => editOrderModal.modal = show" @formSubmit="getOrders()" />

      <OrderDetailModal :data="orderDetailModal.data" :show="orderDetailModal.modal" @showUpdate="show => orderDetailModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import EditOrderModal from './modal/EditOrderModal'
import OrderDetailModal from './modal/OrderDetailModal'

export default {
  name: 'orders',
  components: {
    EditOrderModal,
    OrderDetailModal,
  },
  data () {
    return {
      columns: [ 'sn', 'status', 'order_info.booking_name', 'order_info.booking_phone', 'order_info.booking_note', 'order_info.agent_note', 'total_price', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          sn: this.$t('order_no'),
          status: this.$t('status'),
          'order_info.booking_name': this.$t('booking_name'),
          'order_info.booking_phone': this.$t('booking_phone'),
          'order_info.booking_note': this.$t('booking_note'),
          'order_info.agent_note': this.$t('agent_note'),
          total_price: this.$t('total_price'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'sn', 'status', 'order_info.booking_name', 'order_info.booking_phone', 'order_info.booking_note', 'order_info.agent_note', 'created_at' ],
        filterable: [ 'sn', 'status', 'order_info.booking_name', 'order_info.booking_phone', 'order_info.booking_note', 'order_info.agent_note', 'created_at' ]
      },
      statusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('status') + '...' },
        { value: 'pending', label: this.$t('pending') },
        { value: 'completed', label: this.$t('completed') },
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'refunding', label: this.$t('refunding') },
        { value: 'refunded', label: this.$t('refunded') },
        { value: 'cancelled', label: this.$t('cancelled') },
        { value: 'expired', label: this.$t('expired') },
        { value: 'closed', label: this.$t('closed') },
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { date_range: [this.$moment({hour: 0}).subtract(30, 'd'), this.$moment({hour: 0}).add(30, 'd')], status: null, search_str: '' },
      editOrderModal: { data: {}, modal: false },
      orderDetailModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getOrders()
  },
  methods: {
    getOrders () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getOrders', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
