<template>
  <CModal
    :title="$t('order_detail')"
    size="lg"
    color="dark"
    :closeOnBackdrop="true"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <h4 class="mb-3">{{ $t('order_information') }}</h4>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('order_no')"
          :placeholder="$t('order_no')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('status')"
          :placeholder="$t('status')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="statusOptions.find(option => option.value === orderData.status).label"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('total_price')"
          :placeholder="$t('total_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.total_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_name')"
          :placeholder="$t('booking_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_phone')"
          :placeholder="$t('booking_phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_phone"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('booking_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.order_info.booking_note">
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('agent_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.order_info.agent_note">
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow v-if="orderData.payment_order">
      <CCol col="12" sm="12">
        <hr>
        <h4 class="mb-3">{{ $t('payment_detail') }}</h4>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('status')"
          :placeholder="$t('status')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="paymentStatusOptions.find(option => option.value === orderData.payment_order.status).label"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_price')"
          :placeholder="$t('transfer_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.payment_order.transfer_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_account')"
          :placeholder="$t('transfer_account')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.payment_order.transfer_code"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_time')"
          :placeholder="$t('transfer_time')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(orderData.payment_order.transfer_time).format('YYYY-MM-DD HH:mm:ss')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('transfer_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.payment_order.transfer_note">
          </div>
        </div>
      </CCol>
    </CRow>

    <hr>

    <h4 class="mb-3">{{ $t('order_detail') }}</h4>
    <CRow v-for="(order_room, orderRoomIdx) in orderData.order_rooms" :key="order_room.id">
      <CCol col="12" sm="12" v-if="orderRoomIdx > 0">
        <hr>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('room')"
          :placeholder="$t('room')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_room.room_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('check_in')"
          :placeholder="$t('check_in')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(order_room.start_date).format('YYYY-MM-DD')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('check_out')"
          :placeholder="$t('check_out')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(order_room.end_date).format('YYYY-MM-DD')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12" v-for="(order_room_stock, orderRoomStockIdx) in order_room.order_room_stocks" :key="order_room_stock.id">
        <CInput
          :label="order_room_stock.room_quantity_unit_name"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_room_stock.quantity + ' ' + order_room_stock.room_quantity_unit_measure"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12" v-for="(order_room_option, orderRoomOptionIdx) in order_room.order_room_options" :key="order_room_option.id">
        <CInput
          :label="$t('room_option')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_room_option.room_option_name"
          plaintext
        />
      </CCol>
    </CRow>

    <hr v-if="orderData.order_extra && orderData.order_extra.length > 0">

    <CRow v-for="(order_extra, orderExtraIdx) in orderData.order_extras" :key="order_extra.id">
      <CCol col="12" sm="12" v-if="orderExtraIdx > 0">
        <hr>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('extra')"
          :placeholder="$t('extra')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_extra.extra_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('check_in')"
          :placeholder="$t('check_in')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(order_extra.start_date).format('YYYY-MM-DD')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('check_out')"
          :placeholder="$t('check_out')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(order_extra.end_date).format('YYYY-MM-DD')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12" v-for="(order_extra_stock, orderExtraStockIdx) in order_extra.order_extra_stocks" :key="order_extra_stock.id">
        <CInput
          :label="order_extra_stock.extra_quantity_unit_name"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_extra_stock.quantity + ' ' + order_extra_stock.extra_quantity_unit_measure"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12" v-for="(order_extra_option, orderExtraOptionIdx) in order_extra.order_extra_options" :key="order_extra_option.id">
        <CInput
          :label="$t('extra_option')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="order_extra_option.extra_option_name"
          plaintext
        />
      </CCol>
    </CRow>
    <div slot="footer-wrapper"></div>
  </CModal>
</template>

<script>
export default {
  name: 'OrderDetailModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      statusOptions: [
        { value: 'pending', label: this.$t('pending') },
        { value: 'completed', label: this.$t('completed') },
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'refunding', label: this.$t('refunding') },
        { value: 'refunded', label: this.$t('refunded') },
        { value: 'cancelled', label: this.$t('cancelled') },
        { value: 'expired', label: this.$t('expired') },
        { value: 'closed', label: this.$t('closed') },
      ],
      paymentStatusOptions: [
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'paid', label: this.$t('paid') },
        { value: 'refunded', label: this.$t('refunded') },
      ],
      orderData: {}
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showOrder()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showOrder () {
      const loader = this.$loading.show()
      this.$store.dispatch('showOrder', { id: this.modal.data.id }).then(res => {
        this.orderData = res[0]
        this.orderData.total_price = 'NT' + this.orderData.total_price.toLocaleString('zh-TW', {
                                                  style: 'currency',
                                                  currency: 'TWD',
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                              })
        this.orderData.payment_order && this.orderData.payment_order.transfer_price && (this.orderData.payment_order.transfer_price = 'NT' + this.orderData.payment_order.transfer_price.toLocaleString('zh-TW', {
                                                  style: 'currency',
                                                  currency: 'TWD',
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                              }))
        this.orderData.order_info.booking_note = this.orderData.order_info.booking_note ? this.orderData.order_info.booking_note.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>') : ''
        this.orderData.order_info.agent_note = this.orderData.order_info.agent_note ? this.orderData.order_info.agent_note.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>') : ''
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
  },
  beforeDestroy: function () {
  },
}
</script>
