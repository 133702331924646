<template>
  <CModal
    :title="$t('edit_order')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <h4 class="mb-3">{{ $t('order_information') }}</h4>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('order_no')"
          :placeholder="$t('order_no')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('status')"
          :options="statusOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.status"
          :isValid="valid_formData_status"
        />
      </CCol>
      <CCol col="12" sm="12" v-if="orderData.status !== formData.status">
        <CSelect
          :label="$t('send_notification')"
          :options="notifyOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.notify"
          :isValid="valid_formData_notify"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('total_price')"
          :placeholder="$t('total_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.total_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_name')"
          :placeholder="$t('booking_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('booking_phone')"
          :placeholder="$t('booking_phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.order_info.booking_phone"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('booking_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.order_info.booking_note">
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ $t('agent_note') }}
          </label>
          <div class="col-sm-12">
            <textarea-autosize
              :placeholder="$t('agent_note')"
              :class="['form-control', valid_formData_valid_formData_introduction ? 'is-valid' : 'is-invalid']"
              v-model="formData.agent_note"
              :min-height="100"
              :max-height="350"
            />
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow v-if="orderData.payment_order">
      <CCol col="12" sm="12">
        <hr>
        <h4 class="mb-3">{{ $t('payment_detail') }}</h4>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('status')"
          :placeholder="$t('status')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="paymentStatusOptions.find(option => option.value === orderData.payment_order.status).label"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_price')"
          :placeholder="$t('transfer_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.payment_order.transfer_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_account')"
          :placeholder="$t('transfer_account')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="orderData.payment_order.transfer_code"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('transfer_time')"
          :placeholder="$t('transfer_time')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="$moment(orderData.payment_order.transfer_time).format('YYYY-MM-DD HH:mm:ss')"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">
            {{ $t('transfer_note') }}
          </label>
          <div class="col-form-label col-sm-9" v-html="orderData.payment_order.transfer_note">
          </div>
        </div>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditOrderModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      statusOptions: [
        { value: 'pending', label: this.$t('pending') },
        { value: 'completed', label: this.$t('completed') },
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'refunding', label: this.$t('refunding') },
        { value: 'refunded', label: this.$t('refunded') },
        { value: 'cancelled', label: this.$t('cancelled') },
        { value: 'expired', label: this.$t('expired') },
        { value: 'closed', label: this.$t('closed') },
      ],
      paymentStatusOptions: [
        { value: 'confirming', label: this.$t('confirming') },
        { value: 'paid', label: this.$t('paid') },
        { value: 'refunded', label: this.$t('refunded') },
      ],
      notifyOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('send_notification') + '...' },
        { value: 1, label: this.$t('yes') },
        { value: 0, label: this.$t('no') },
      ],
      formData: {},
      orderData: {}
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showOrder()
      }
    }
  },
  computed: {
    valid_formData_status () {
      return this.formData.status ? true : false
    },
    valid_formData_notify () {
      return this.formData.notify === 0 || this.formData.notify === 1 ? true : false
    },
    valid_formData_valid_formData_introduction () {
      return true
    },
    valid_submit () {
      return this.valid_formData_status && this.valid_formData_notify && this.valid_formData_valid_formData_introduction
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showOrder () {
      const loader = this.$loading.show()
      this.$store.dispatch('showOrder', { id: this.modal.data.id }).then(res => {
        this.formData = { id: res[0].id, status: res[0].status, notify: 1, agent_note: res[0].order_info?.agent_note || '' }
        this.orderData = res[0]
        this.orderData.total_price = 'NT' + this.orderData.total_price.toLocaleString('zh-TW', {
                                                  style: 'currency',
                                                  currency: 'TWD',
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                              })
        this.orderData.payment_order && this.orderData.payment_order.transfer_price && (this.orderData.payment_order.transfer_price = 'NT' + this.orderData.payment_order.transfer_price.toLocaleString('zh-TW', {
                                                  style: 'currency',
                                                  currency: 'TWD',
                                                  minimumFractionDigits: 0,
                                                  maximumFractionDigits: 0
                                              }))
        this.orderData.order_info.booking_note = this.orderData.order_info.booking_note ? this.orderData.order_info.booking_note.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>') : ''
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateOrder', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
